<template>
  <div class="BaseServicos">
    <NavBar id="anav" :options="navbarLinks" />
    <router-view id="visaoRota" />
    <Footer id="rodape" />
  </div>
</template>

<script>
import Footer from "@/components/footer.vue";
import NavBar from "@/components/NavBar.vue";

export default {
  components: { NavBar, Footer },
  data() {
    return {
      navbarLinks: [
        { titulo: "Corte", link: "corte" },
        { titulo: "Dobra", link: "dobra" },
        { titulo: "Estamparia", link: "estamparia" },
        { titulo: "Ferramentaria", link: "ferramentaria" },
        { titulo: "Galvanoplastia", link: "galvanoplastia" },
        { titulo: "Pintura", link: "pintura" },
        { titulo: "Solda", link: "solda" },
      ],
    };
  },
};
</script>

<style scoped>
#anav {
  top: 0;
  z-index: 300;
}
.BaseServicos {
  padding-top: 120px;
  min-height: 100vh;
  position: relative;
  padding-bottom: 120px;
}

#rodape {
  position: absolute;
  bottom: 35px;
  width: 100%;
}
</style>